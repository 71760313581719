$COL1: rgba(0,0,0,0); //
$COLFLAT1: #808080; //
$COL2: #e3e3e4; //
$COLFLAT2: #e3e3e4; //
$COL3: #2b2e34; //
$COLFLAT3: #2b2e34; //
$COL4: #2b2e34; //
$COLFLAT4: #2b2e34; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #43a047; //
$COLFLAT6: #43a047; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #4c515b; //
$COLFLAT9: #4c515b; //
$COL10: #f2f2f2; //
$COLFLAT10: #f2f2f2; //
$COL11: #e8e8e8; //
$COLFLAT11: #e8e8e8; //
$COL12: #80bce7; //
$COLFLAT12: #80bce7; //
$COL13: #ffffff; //
$COLFLAT13: #ffffff; //
$COL14: #2b2e34; //
$COLFLAT14: #2b2e34; //
$COL15: #4c515b; //
$COLFLAT15: #4c515b; //
$COL16: #999999; //Grey
$COLFLAT16: #999999; //Grey
$COL17: #009cde; //PayPal Blue
$COLFLAT17: #009cde; //PayPal Blue
$COL18: rgba(255,255,255,0.49); //White trans
$COLFLAT18: #bebebe; //White trans
$COL19: rgba(0,0,0,0.4); //Black Transparent
$COLFLAT19: #4d4d4d; //Black Transparent
$COL20: #3c2213; //
$COLFLAT20: #3c2213; //
$COL21: #9fa5b3; //
$COLFLAT21: #9fa5b3; //
$COL22: #ffffff; //
$COLFLAT22: #ffffff; //
$COL23: #ffffff; //
$COLFLAT23: #ffffff; //
$COL24: #dddddd; //
$COLFLAT24: #dddddd; //
$COL25: #d5d5d5; //Mid Grey
$COLFLAT25: #d5d5d5; //Mid Grey
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1260px,
  xxlarge: 1936px,
);
$global-width: 1260px;
